import React, { useState, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { isBrowser } from "../../../context/ApolloContext"

const ExclusifStickyBannerWrapper = styled.div`
  width: 100%;
  padding: 8px 20px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  background: ${props => props.theme.colors.primary.exclusif};
  color: #fff;
  position: relative;

  @media (max-width: 767px) {
    padding: 5px 20px;
  }

  @media (max-width: 550px) {
    padding: 5px 10px;
  }
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const TextWrapper = styled.div`
  width: 100%;
  margin: 0px auto;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
`

const LinkBox = styled(Link)`
  width: 100%;
  position: absolute;
  height: 100%;
  z-index: 1;
`

const LinkButtonTitle = styled.span`
  display: none;
`

const Text = styled.div`
  font-size: 18px;
  line-height: 30px;
  font-family: "Museo";
  font-weight: 500;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  cursor: pointer;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 550px) {
    font-size: 14px;
  }
`

const FirstText = styled.div`
  font-size: 28px;
  line-height: 1.2;
  font-family: "BebasNeueBold";
  font-weight: bold;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  span {
    background: #fff;
    color: ${props => props.theme.colors.primary.exclusif};
    font-size: 24px;
    line-height: 1;
    padding: 8px 8px 6px 8px;
    border-radius: 40px;
    margin-left: 10px;

    @media (max-width: 450px) {
      font-size: 18px;
      padding: 5px 5px 5px;
    }

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    flex-basis: ${({ flexBasis }) => (flexBasis ? "100%" : "auto")};
    justify-content: center;
  }

  @media (max-width: 450px) {
    font-size: 22px;
  }

  @media (max-width: 360px) {
    font-size: 20px;
  }
`

const SecondText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 20px;

  span {
    font-size: 50px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 10px;
    line-height: 1;

    @media (max-width: 450px) {
      font-size: 35px;
    }

    @media (max-width: 360px) {
      font-size: 28px;
    }
  }

  div {
    text-align: left;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 7px;
  }

  @media (max-width: 767px) {
    margin-left: 0px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

const ThirdText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 20px;

  span {
    font-size: 50px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 10px;
    line-height: 1;

    @media (max-width: 450px) {
      font-size: 35px;
    }

    @media (max-width: 360px) {
      font-size: 28px;
    }
  }

  div {
    text-align: left;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 7px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

const FourthText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 7px;

  span:first-of-type {
    font-size: 40px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 7px;
    line-height: 1;

    @media (max-width: 550px) {
      margin-right: 10px;
    }

    @media (max-width: 450px) {
      font-size: 35px;
    }

    @media (max-width: 360px) {
      font-size: 28px;
    }
  }

  span:nth-of-type(2) {
    background: #fff;
    color: ${props => props.theme.colors.primary.exclusif};
    font-size: 24px;
    line-height: 1;
    font-family: "BebasNeueBold";
    font-weight: bold;
    padding: 8px 8px 6px 8px;
    border-radius: 40px;
    margin-left: 7px;

    @media (max-width: 550px) {
      margin-left: 10px;
    }

    @media (max-width: 450px) {
      font-size: 20px;
      padding: 5px 5px 5px;
    }

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  div {
    text-align: left;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 7px;
  }

  @media (max-width: 767px) {
    margin-left: 0px;
    margin-left: 10px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

const FifthText = styled.div`
  font-size: 14px;
  line-height: 1.2;
  display: flex;
  font-family: "Museo";
  font-weight: 500;
  align-items: center;
  margin-left: 7px;

  span:first-of-type {
    font-size: 50px;
    font-family: "BebasNeueBold";
    font-weight: bold;
    margin-right: 7px;
    line-height: 1;

    @media (max-width: 550px) {
      margin-right: 10px;
    }

    @media (max-width: 450px) {
      font-size: 35px;
    }

    @media (max-width: 360px) {
      font-size: 28px;
    }
  }

  span:nth-of-type(2) {
    background: #fff;
    color: ${props => props.theme.colors.primary.exclusif};
    font-size: 24px;
    line-height: 1;
    font-family: "BebasNeueBold";
    font-weight: bold;
    padding: 8px 8px 6px 8px;
    border-radius: 40px;
    margin-left: 7px;

    @media (max-width: 550px) {
      margin-left: 10px;
    }

    @media (max-width: 450px) {
      font-size: 20px;
      padding: 5px 5px 5px;
    }

    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  div {
    text-align: left;
  }

  strong {
    font-weight: 700;
  }

  @media (max-width: 850px) {
    margin-left: 7px;
  }

  @media (max-width: 450px) {
    font-size: 13px;
  }
`

const CloseButton = styled.a`
  background: none;
  color: #fff;
  font-size: 20px;
  padding: 0px;
  box-shadow: none;
  position: absolute;
  line-height: 10px;
  font-weight: normal;
  right: 5px;
  top: 5px;
  margin: 0px;
  cursor: pointer;
  z-index: 1;

  :hover {
    color: #fff;
  }
`

const ExclusifStickyBanner = ({ intl }) => {
  const [LyneHomeStickyHide, setLyneHomeStickyHide] = useState(false)
  const { pathname } = useLocation()
  var currentPath = ""

  let isOreillerPage = false
  let isMatelasPage = false
  let isSurmatelasPage = false
  let isSommierPage = false
  let isLynePage = false
  let isChaisePage = false

  let oreillerRegex = /(oreiller|oreillers-ergonomiques)/
  let matelasRegex = /(matelas)/
  let surmatelasRegex = /(surmatelas)/
  let sommierRegex = /(sommier|lit-electrique)/
  let lyneRegex = /(solutions-textiles|wearable-technology)/
  let chaiseRegex = /(chaise-bureau-ergonomique)/

  if (isBrowser) {
    currentPath = window.location.pathname

    isOreillerPage = oreillerRegex.test(currentPath)
    isMatelasPage = matelasRegex.test(currentPath)
    isSurmatelasPage = surmatelasRegex.test(currentPath)
    isSommierPage = sommierRegex.test(currentPath)
    isLynePage = lyneRegex.test(currentPath)
    isChaisePage = chaiseRegex.test(currentPath)
  }

  const hideBanner = () => {
    isBrowser && sessionStorage.setItem("ExclusifStickyBanner", true)
    setLyneHomeStickyHide(true)
  }
  const checkVisibilityBanner = () => {
    if (isBrowser) {
      sessionStorage.getItem("ExclusifStickyBanner") &&
        setLyneHomeStickyHide(true)
    }
  }

  useEffect(() => {
    checkVisibilityBanner()
  }, [intl.locale])

  return (
    !LyneHomeStickyHide && (
      <ExclusifStickyBannerWrapper
        lang={intl.locale}
        className="LyneHomeStickyBanner"
      >
        <Container>
          {isSurmatelasPage ? (
            <TextWrapper>
              <Text lang={intl.locale}>
                <FirstText>
                  Pendant 24h avec le code <span>WOW-EXCLU</span>
                </FirstText>
                <FourthText>
                  <span>-25%</span>
                  <div>
                    <strong>sur le surmatelas</strong>
                  </div>
                </FourthText>
              </Text>
            </TextWrapper>
          ) : isChaisePage ? (
            <TextWrapper>
              <Text lang={intl.locale}>
                <FirstText>
                  Pendant 24h avec le code <span>WOW-EXCLU</span>
                </FirstText>
                <FourthText>
                  <span>-120€</span>
                  <div>
                    <strong>sur la chaise ergonomique</strong>
                  </div>
                </FourthText>
              </Text>
            </TextWrapper>
          ) : isMatelasPage ? (
            <TextWrapper>
              <Text lang={intl.locale}>
                <FirstText>
                  Pendant 24h avec le code <span>WOW-EXCLU</span>
                </FirstText>
                <FourthText>
                  <span>-35%</span>
                  <div>
                    <strong>sur les packs matelas + oreiller</strong>
                  </div>
                </FourthText>
              </Text>
            </TextWrapper>
          ) : isOreillerPage ? (
            <TextWrapper>
              <Text lang={intl.locale}>
                <FirstText>
                  Pendant 24h avec le code <span>WOW-EXCLU</span>
                </FirstText>
                <FourthText>
                  <span>-35%</span>
                  <div>
                    <strong>sur les packs 3 oreillers</strong>
                  </div>
                </FourthText>
              </Text>
            </TextWrapper>
          ) : isSommierPage ? (
            <TextWrapper>
              <Text lang={intl.locale}>
                <FirstText>
                  Pendant 24h avec le code <span>WOW-EXCLU</span>
                </FirstText>
                <FourthText>
                  <span>-40%</span>
                  <div>
                    <strong>sur les packs matelas + sommier</strong>
                  </div>
                </FourthText>
              </Text>
            </TextWrapper>
          ) : isLynePage ? (
            <TextWrapper>
              <Text lang={intl.locale}>
                <FirstText flexBasis="100%">
                  Pendant 24h avec le code <span>WOW-EXCLU</span>
                </FirstText>
                <SecondText>
                  <span>-20€</span>
                  <div>
                    sur <strong>1 T-shirt</strong>
                  </div>
                </SecondText>
                <ThirdText>
                  <span>-80€</span>
                  <div>
                    sur <strong>2 T-shirts</strong>
                  </div>
                </ThirdText>
              </Text>
            </TextWrapper>
          ) : (
            <TextWrapper>
              <LinkBox to="/shop/offres-exclusives/">
                <LinkButtonTitle>Je découvre</LinkButtonTitle>
              </LinkBox>
              <Text lang={intl.locale}>
                <FirstText>Pendant 24h :</FirstText>
                <FifthText>
                  <div style={{ marginRight: 7 }}>
                    <strong>Jusqu’à</strong>
                  </div>
                  <span>-40%</span>
                  <div>
                    <strong>avec le code</strong>
                  </div>
                  <span>WOW-EXCLU</span>
                </FifthText>
              </Text>
            </TextWrapper>
          )}
        </Container>
        <CloseButton onClick={hideBanner}>×</CloseButton>
      </ExclusifStickyBannerWrapper>
    )
  )
}

export default injectIntl(ExclusifStickyBanner)
